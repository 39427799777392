import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import elementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css'; // import element-plus css style
import jaLocale from 'element-plus/lib/locale/lang/ja';
import './assets/css/comStyle.scss';

jaLocale.el.pagination = {
  goto: '',
  pagesize: '',
  total: '',
  pageClassifier: '',
};

router.beforeEach((to, from, next) => {
  // Deal with the following operations before route jumps.
  const validPaths = ['Login', 'UC516S02', 'UC502S01', 'UC502S02', 'UC819S17', 'UC520S01', 'UC819S18', 'UC819S19', 'UC819S02', 'UC819S21', 'UC819S22', 'UC819S24', 'UC819S25', 'UC819S32'];
  // 画面タイトルを変更
  if (typeof to.meta.title === 'string') {
    document.title = to.meta.title;
  }

  if (validPaths.includes(to.name as string)) {
    // If go to the login view
    next(); // Let it pass
  } else {
    // If not
    if (sessionStorage.getItem('userId')) {
      // Justify the browser session, whether have stored the login information.
      next(); // If it have stored the user information ,go to the view you wanna go.
    } else {
      // Else
      next({ path: '/login' }); // Go to the login  view
    }
  }
});

createApp(App)
  .use(store)
  .use(router)
  .use(elementPlus, {
    locale: jaLocale,
  })
  .mount('#app');
