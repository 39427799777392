import { createRouter, createWebHistory, RouteRecordRaw, useRouter } from 'vue-router';
import MainBoard from '../components/MainBoard.vue';

//ログイン
import Login from '../views/UC/login/UC514S02.vue';

// トップメニュー
const UC514S01 = () => import('@/views/UC/top-menu/UC514S01.vue');

// 在庫管理
//新規入庫
const UC803S01 = () => import('@/views/UC/stock-manage/new-receive/UC803S01.vue');
const UC803S02 = () => import('@/views/UC/stock-manage/new-receive/UC803S02.vue');
const UC803S03 = () => import('@/views/UC/stock-manage/new-receive/UC803S03.vue');
const UC803S04 = () => import('@/views/UC/stock-manage/new-receive/UC803S04.vue');

//貸出出庫
const UC804S01 = () => import('@/views/UC/stock-manage/lend-issue/UC804S01.vue');
const UC804S02 = () => import('@/views/UC/stock-manage/lend-issue/UC804S02.vue');
const UC804S03 = () => import('@/views/UC/stock-manage/lend-issue/UC804S03.vue');
const UC804S04 = () => import('@/views/UC/stock-manage/lend-issue/UC804S04.vue');

//戻し入庫
const UC805S01 = () => import('@/views/UC/stock-manage/return-receive/UC805S01.vue');
const UC805S02 = () => import('@/views/UC/stock-manage/return-receive/UC805S02.vue');
const UC805S03 = () => import('@/views/UC/stock-manage/return-receive/UC805S03.vue');
const UC805S04 = () => import('@/views/UC/stock-manage/return-receive/UC805S04.vue');

//出庫指示
const UC806S01 = () => import('@/views/UC/stock-manage/issue-order/UC806S01.vue');
const UC806S02 = () => import('@/views/UC/stock-manage/issue-order/UC806S02.vue');
const UC806S03 = () => import('@/views/UC/stock-manage/issue-order/UC806S03.vue');
const UC806S04 = () => import('@/views/UC/stock-manage/issue-order/UC806S04.vue');

//廃棄指示
const UC807S01 = () => import('@/views/UC/stock-manage/discard-order/UC807S01.vue');
const UC807S02 = () => import('@/views/UC/stock-manage/discard-order/UC807S02.vue');
const UC807S03 = () => import('@/views/UC/stock-manage/discard-order/UC807S03.vue');
const UC807S04 = () => import('@/views/UC/stock-manage/discard-order/UC807S04.vue');

//閲覧指示
const UC808S01 = () => import('@/views/UC/stock-manage/view-order/UC808S01.vue');
const UC808S02 = () => import('@/views/UC/stock-manage/view-order/UC808S02.vue');
const UC808S03 = () => import('@/views/UC/stock-manage/view-order/UC808S03.vue');
const UC808S04 = () => import('@/views/UC/stock-manage/view-order/UC808S04.vue');

//文書変更
const UC809S01 = () => import('@/views/UC/stock-manage/documents-change/UC809S01.vue');
const UC809S02 = () => import('@/views/UC/stock-manage/documents-change/UC809S02.vue');
const UC809S03 = () => import('@/views/UC/stock-manage/documents-change/UC809S03.vue');
const UC809S04 = () => import('@/views/UC/stock-manage/documents-change/UC809S04.vue');
const UC809S05 = () => import('@/views/UC/stock-manage/documents-change/UC809S05.vue');

// 各種情報照会
//サンプル
const UC520S01 = () => import('@/views/UC/information/UC520S01.vue');

//在庫照会/明細管理
const UC520S02 = () => import('@/views/UC/valious-info-inquiry/UC520S02.vue');

//在庫ダウンロード
const UC812S01 = () => import('@/views/UC/valious-info-inquiry/stock-download/UC812S01.vue');

//履歴照会
const UC813S01 = () => import('@/views/UC/valious-info-inquiry/history-inquiry/UC813S01.vue');
const UC813S02 = () => import('@/views/UC/valious-info-inquiry/history-inquiry/UC813S02.vue');

//オーダー履歴参照
const UC814S01 = () => import('@/views/UC/valious-info-inquiry/order-history-reference/UC814S01.vue');
const UC814S02 = () => import('@/views/UC/valious-info-inquiry/order-history-reference/UC814S02.vue');

//オーダー履歴ダウンロード
const UC815S01 = () => import('@/views/UC/valious-info-inquiry/order-history-download/UC815S01.vue');

//文書明細アップロード
const UC820S01 = () => import('@/views/UC/valious-info-inquiry/stock-download/UC820S01.vue');

//管理者メニュー
//ご承認待ち
const UC816S01 = () => import('@/views/UC/manager-menu/approval-pend/UC816S01.vue');
const UC816S02 = () => import('@/views/UC/manager-menu/approval-pend/UC816S02.vue');
const UC816S03 = () => import('@/views/UC/manager-menu/approval-pend/UC816S03.vue');
const UC816S04 = () => import('@/views/UC/manager-menu/approval-pend/UC816S04.vue');
const UC816S05 = () => import('@/views/UC/manager-menu/approval-pend/UC816S05.vue');
const UC816S06 = () => import('@/views/UC/manager-menu/approval-pend/UC816S06.vue');
const UC816S07 = () => import('@/views/UC/manager-menu/approval-pend/UC816S07.vue');
const UC816S08 = () => import('@/views/UC/manager-menu/approval-pend/UC816S08.vue');
const UC816S09 = () => import('@/views/UC/manager-menu/approval-pend/UC816S09.vue');
const UC816S10 = () => import('@/views/UC/manager-menu/approval-pend/UC816S10.vue');
const UC816S11 = () => import('@/views/UC/manager-menu/approval-pend/UC816S11.vue');
const UC816S12 = () => import('@/views/UC/manager-menu/approval-pend/UC816S12.vue');
const UC816S13 = () => import('@/views/UC/manager-menu/approval-pend/UC816S13.vue');
const UC816S14 = () => import('@/views/UC/manager-menu/approval-pend/UC816S14.vue');

//お問い合わせ
//文書箱発注
const UC817S01 = () => import('@/views/UC/inquiry/document-box-order/UC817S01.vue');
const UC817S02 = () => import('@/views/UC/inquiry/document-box-order/UC817S02.vue');
const UC817S03 = () => import('@/views/UC/inquiry/document-box-order/UC817S03.vue');
const UC817S04 = () => import('@/views/UC/inquiry/document-box-order/UC817S04.vue');

//連絡事項
const UC818S01 = () => import('@/views/UC/inquiry/notifications/UC818S01.vue');
const UC818S02 = () => import('@/views/UC/inquiry/notifications/UC818S02.vue');
const UC818S03 = () => import('@/views/UC/inquiry/notifications/UC818S03.vue');
const UC818S04 = () => import('@/views/UC/inquiry/notifications/UC818S04.vue');

//管理者メニュー
//ユーザー登録
const UC819S01 = () => import('@/views/UC/admin-menu/user-regist/UC819S01.vue');
const UC819S02 = () => import('@/views/UC/admin-menu/user-regist/UC819S02.vue');
const UC819S03 = () => import('@/views/UC/admin-menu/user-regist/UC819S03.vue');
const UC819S04 = () => import('@/views/UC/admin-menu/user-regist/UC819S04.vue');
const UC819S05 = () => import('@/views/UC/admin-menu/user-regist/UC819S05.vue');
const UC819S06 = () => import('@/views/UC/admin-menu/user-regist/UC819S06.vue');
const UC819S07 = () => import('@/views/UC/admin-menu/user-regist/UC819S07.vue');
const UC819S08 = () => import('@/views/UC/admin-menu/user-regist/UC819S08.vue');
const UC819S09 = () => import('@/views/UC/admin-menu/user-regist/UC819S09.vue');
const UC819S10 = () => import('@/views/UC/admin-menu/user-regist/UC819S10.vue');
const UC819S11 = () => import('@/views/UC/admin-menu/user-regist/UC819S11.vue');
const UC819S12 = () => import('@/views/UC/admin-menu/user-regist/UC819S12.vue');
const UC819S13 = () => import('@/views/UC/admin-menu/user-regist/UC819S13.vue');
const UC819S14 = () => import('@/views/UC/admin-menu/user-regist/UC819S14.vue');
const UC819S15 = () => import('@/views/UC/admin-menu/user-regist/UC819S15.vue');
const UC819S16 = () => import('@/views/UC/admin-menu/user-regist/UC819S16.vue');
const UC819S17 = () => import('@/views/UC/admin-menu/user-regist/UC819S17.vue');
const UC819S18 = () => import('@/views/UC/admin-menu/user-regist/UC819S18.vue');
const UC819S19 = () => import('@/views/UC/admin-menu/user-regist/UC819S19.vue');
const UC819S20 = () => import('@/views/UC/admin-menu/user-regist/UC819S20.vue');
const UC819S21 = () => import('@/views/UC/admin-menu/user-regist/UC819S21.vue');
const UC819S22 = () => import('@/views/UC/admin-menu/user-regist/UC819S22.vue');
const UC819S23 = () => import('@/views/UC/admin-menu/user-regist/UC819S23.vue');
const UC819S24 = () => import('@/views/UC/admin-menu/user-regist/UC819S24.vue');
const UC819S25 = () => import('@/views/UC/admin-menu/user-regist/UC819S25.vue');
const UC819S26 = () => import('@/views/UC/admin-menu/user-regist/UC819S26.vue');
const UC819S27 = () => import('@/views/UC/admin-menu/user-regist/UC819S27.vue');
const UC819S28 = () => import('@/views/UC/admin-menu/user-regist/UC819S28.vue');
const UC819S29 = () => import('@/views/UC/admin-menu/user-regist/UC819S29.vue');
const UC819S30 = () => import('@/views/UC/admin-menu/user-regist/UC819S30.vue');
const UC819S31 = () => import('@/views/UC/admin-menu/user-regist/UC819S31.vue');
const UC819S32 = () => import('@/views/UC/admin-menu/user-regist/UC819S32.vue');



// ユーザー管理
const UC511S01 = () => import('@/views/UC/user-management/UC511S01.vue');
const UC502S01 = () => import('@/views/UC/user-management/UC502S01.vue');
const UC502S02 = () => import('@/views/UC/user-management/UC502S02.vue');
const UC505S01 = () => import('@/views/UC/user-management/UC505S01.vue');
const UC505S02 = () => import('@/views/UC/user-management/UC505S02.vue');
const UC508S01 = () => import('@/views/UC/user-management/UC508S01.vue');
const UC513S01 = () => import('@/views/UC/user-management/UC513S01.vue');

//パスワード設定
const UC516S02 = () => import('@/views/UC/password-reset/UC516S02.vue');

const routes: Array<RouteRecordRaw> = [
  {
    path: '/information',
    component: MainBoard,
    redirect: '/information/UC520S01',
    children: [
      {
        path: '/information/UC520S01',
        name: 'UC520S01',
        meta: { title: 'サンプル' },
        component: UC520S01,
      },
      
    ]
  },
  {
    path: '/top-menu',
    component: MainBoard,
    redirect: '/top-menu/UC514S01',
    children: [
      {
        path: '/top-menu/UC514S01',
        name: 'UC514S01',
        meta: { title: 'トップメニュー' },
        component: UC514S01,
      },
      
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/new-receive/UC803S01',
    children: [
      {
        path: '/stock-manage/new-receive/UC803S01',
        name: 'UC803S01',
        meta: { title: '新規入庫' },
        component: UC803S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/new-receive/UC803S02',
    children: [
      {
        path: '/stock-manage/new-receive/UC803S02',
        name: 'UC803S02',
        meta: { title: '新規入庫確認' },
        component: UC803S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/new-receive/UC803S03',
    children: [
      {
        path: '/stock-manage/new-receive/UC803S03',
        name: 'UC803S03',
        meta: { title: '新規入庫完了' },
        component: UC803S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/new-receive/UC803S04',
    children: [
      {
        path: '/stock-manage/new-receive/UC803S04',
        name: 'UC803S04',
        meta: { title: '新規入庫完了' },
        component: UC803S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/lend-issue/UC804S01',
    children: [
      {
        path: '/stock-manage/lend-issue/UC804S01',
        name: 'UC804S01',
        meta: { title: '貸出出庫' },
        component: UC804S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/lend-issue/UC804S02',
    children: [
      {
        path: '/stock-manage/lend-issue/UC804S02',
        name: 'UC804S02',
        meta: { title: '貸出出庫確認' },
        component: UC804S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/lend-issue/UC804S03',
    children: [
      {
        path: '/stock-manage/lend-issue/UC804S03',
        name: 'UC804S03',
        meta: { title: '貸出出庫完了' },
        component: UC804S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/lend-issue/UC804S04',
    children: [
      {
        path: '/stock-manage/lend-issue/UC804S04',
        name: 'UC804S04',
        meta: { title: '貸出出庫完了' },
        component: UC804S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/return-receive/UC805S01',
    children: [
      {
        path: '/stock-manage/return-receive/UC805S01',
        name: 'UC805S01',
        meta: { title: '戻し入庫' },
        component: UC805S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/return-receive/UC805S02',
    children: [
      {
        path: '/stock-manage/return-receive/UC805S02',
        name: 'UC805S02',
        meta: { title: '戻し入庫確認' },
        component: UC805S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/return-receive/UC805S03',
    children: [
      {
        path: '/stock-manage/return-receive/UC805S03',
        name: 'UC805S03',
        meta: { title: '戻し入庫完了' },
        component: UC805S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/return-receive/UC805S04',
    children: [
      {
        path: '/stock-manage/return-receive/UC805S04',
        name: 'UC805S04',
        meta: { title: '戻し入庫完了' },
        component: UC805S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/issue-order/UC806S01',
    children: [
      {
        path: '/stock-manage/issue-order/UC806S01',
        name: 'UC806S01',
        meta: { title: '出庫指示' },
        component: UC806S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/issue-order/UC806S02',
    children: [
      {
        path: '/stock-manage/issue-order/UC806S02',
        name: 'UC806S02',
        meta: { title: '出庫指示確認' },
        component: UC806S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/issue-order/UC806S03',
    children: [
      {
        path: '/stock-manage/issue-order/UC806S03',
        name: 'UC806S03',
        meta: { title: '出庫指示完了' },
        component: UC806S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/issue-order/UC806S04',
    children: [
      {
        path: '/stock-manage/issue-order/UC806S04',
        name: 'UC806S04',
        meta: { title: '出庫指示完了' },
        component: UC806S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/discard-order/UC807S01',
    children: [
      {
        path: '/stock-manage/discard-order/UC807S01',
        name: 'UC807S01',
        meta: { title: '廃棄指示' },
        component: UC807S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/discard-order/UC807S02',
    children: [
      {
        path: '/stock-manage/discard-order/UC807S02',
        name: 'UC807S02',
        meta: { title: '廃棄指示確認' },
        component: UC807S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/discard-order/UC807S03',
    children: [
      {
        path: '/stock-manage/discard-order/UC807S03',
        name: 'UC807S03',
        meta: { title: '廃棄指示完了' },
        component: UC807S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/discard-order/UC807S04',
    children: [
      {
        path: '/stock-manage/discard-order/UC807S04',
        name: 'UC807S04',
        meta: { title: '廃棄指示完了' },
        component: UC807S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/view-order/UC808S01',
    children: [
      {
        path: '/stock-manage/view-order/UC808S01',
        name: 'UC808S01',
        meta: { title: '閲覧指示' },
        component: UC808S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/view-order/UC808S02',
    children: [
      {
        path: '/stock-manage/view-order/UC808S02',
        name: 'UC808S02',
        meta: { title: '閲覧指示確認' },
        component: UC808S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/view-order/UC808S03',
    children: [
      {
        path: '/stock-manage/view-order/UC808S03',
        name: 'UC808S03',
        meta: { title: '閲覧指示完了' },
        component: UC808S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/view-order/UC808S04',
    children: [
      {
        path: '/stock-manage/view-order/UC808S04',
        name: 'UC808S04',
        meta: { title: '閲覧指示完了' },
        component: UC808S04,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/documents-change/UC809S01',
    children: [
      {
        path: '/stock-manage/documents-change/UC809S01',
        name: 'UC809S01',
        meta: { title: '文書変更' },
        component: UC809S01,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/documents-change/UC809S02',
    children: [
      {
        path: '/stock-manage/documents-change/UC809S02',
        name: 'UC809S02',
        meta: { title: '文書変更依頼入力' },
        component: UC809S02,
      }
    ]
  },
  {
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/documents-change/UC809S03',
    children: [
      {
        path: '/stock-manage/documents-change/UC809S03',
        name: 'UC809S03',
        meta: { title: '文書変更依頼確認' },
        component: UC809S03,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/documents-change/UC809S04',
    children: [
      {
        path: '/stock-manage/documents-change/UC809S04',
        name: 'UC809S04',
        meta: { title: '文書変更依頼完了' },
        component: UC809S04,
      }
    ]
  },{
    path: '/stock-manage',
    component: MainBoard,
    redirect: '/stock-manage/documents-change/UC809S05',
    children: [
      {
        path: '/stock-manage/documents-change/UC809S05',
        name: 'UC809S05',
        meta: { title: '文書変更依頼完了(要承認)' },
        component: UC809S05,
      }
    ]
  },
{
    path: '/user-management/UC502S01',
    name: 'UC502S01',
    meta: { title: '利用規約' },
    component: UC502S01,
  },
  {
    path: '/user-management/UC502S02',
    name: 'UC502S02',
    meta: { title: 'ユーザーID登録申請' },
    component: UC502S02,
  },
  {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/stock-download/UC812S01',
    children: [
      {
        path: '/valious-info-inquiry/stock-download/UC812S01',
        name: 'UC812S01',
        meta: { title: '在庫ダウンロード' },
        component: UC812S01,
      }
    ]
  },{
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/hisotry-inquiry/UC813S01',
    children: [
      {
        path: '/valious-info-inquiry/history-inquiry/UC813S01',
        name: 'UC813S01',
        meta: { title: '履歴照会' },
        component: UC813S01,
      }
    ]
  },{
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/hisotry-inquiry/UC813S02',
    children: [
      {
        path: '/valious-info-inquiry/history-inquiry/UC813S02',
        name: 'UC813S02',
        meta: { title: '履歴照会　検索結果' },
        component: UC813S02,
      }
    ]
  },
  {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/order-history-reference/UC814S01',
    children: [
      {
        path: '/valious-info-inquiry/order-history-reference/UC814S01',
        name: 'UC814S01',
        meta: { title: 'オーダー履歴参照' },
        component: UC814S01,
      }
    ]
  },
  {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/order-history-reference/UC814S02',
    children: [
      {
        path: '/valious-info-inquiry/order-history-reference/UC814S02',
        name: 'UC814S02',
        meta: { title: 'オーダー履歴参照 検索結果' },
        component: UC814S02,
      }
    ]
  }, {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/order-history-download/UC815S01',
    children: [
      {
        path: '/valious-info-inquiry/order-history-download/UC815S01',
        name: 'UC815S01',
        meta: { title: 'オーダー履歴ダウンロード' },
        component: UC815S01,
      }
    ]
  },
  {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/stock-download/UC820S01',
    children: [
      {
        path: '/valious-info-inquiry/stock-download/UC820S01',
        name: 'UC820S01',
        meta: { title: '文書明細アップロード' },
        component: UC820S01,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S01',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S01',
        name: 'UC816S01',
        meta: { title: 'ご承認待ち' },
        component: UC816S01,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S02',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S02',
        name: 'UC816S02',
        meta: { title: 'ご承認待ち一覧' },
        component: UC816S02,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S03',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S03',
        name: 'UC816S03',
        meta: { title: 'ご承認・却下完了' },
        component: UC816S03,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S04',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S04',
        name: 'UC816S04',
        meta: { title: 'ご承認待ち確認' },
        component: UC816S04,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S05',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S05',
        name: 'UC816S05',
        meta: { title: 'ご承認待ち一覧' },
        component: UC816S05,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S06',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S06',
        name: 'UC816S06',
        meta: { title: '再入力確認' },
        component: UC816S06,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S07',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S07',
        name: 'UC816S07',
        meta: { title: '再入力確認' },
        component: UC816S07,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S08',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S08',
        name: 'UC816S08',
        meta: { title: '再入力確認' },
        component: UC816S08,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S09',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S09',
        name: 'UC816S09',
        meta: { title: '再入力確認' },
        component: UC816S09,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S10',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S10',
        name: 'UC816S10',
        meta: { title: '再入力確認' },
        component: UC816S10,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S11',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S11',
        name: 'UC816S11',
        meta: { title: '再入力確認' },
        component: UC816S11,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S12',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S12',
        name: 'UC816S12',
        meta: { title: '再入力確認' },
        component: UC816S12,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S13',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S13',
        name: 'UC816S13',
        meta: { title: '再入力確認' },
        component: UC816S13,
      }
    ]
  },{
    path: '/manager-menu',
    component: MainBoard,
    redirect: '/manager-menu/approval-pend/UC816S14',
    children: [
      {
        path: '/manager-menu/approval-pend/UC816S14',
        name: 'UC816S14',
        meta: { title: 'ご承認待ち' },
        component: UC816S14,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/document-box-order/UC817S01',
    children: [
      {
        path: '/inquiry/document-box-order/UC817S01',
        name: 'UC817S01',
        meta: { title: '文書箱発注' },
        component: UC817S01,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/document-box-order/UC817S02',
    children: [
      {
        path: '/inquiry/document-box-order/UC817S02',
        name: 'UC817S02',
        meta: { title: '文書箱発注確認' },
        component: UC817S02,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/document-box-order/UC817S03',
    children: [
      {
        path: '/inquiry/document-box-order/UC817S03',
        name: 'UC817S03',
        meta: { title: '文書箱発注完了' },
        component: UC817S03,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/document-box-order/UC817S04',
    children: [
      {
        path: '/inquiry/document-box-order/UC817S04',
        name: 'UC817S04',
        meta: { title: '文書箱発注完了' },
        component: UC817S04,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/notifications/UC818S01',
    children: [
      {
        path: '/inquiry/notifications/UC818S01',
        name: 'UC818S01',
        meta: { title: '連絡事項' },
        component: UC818S01,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/notifications/UC818S02',
    children: [
      {
        path: '/inquiry/notifications/UC818S02',
        name: 'UC818S02',
        meta: { title: '連絡事項確認' },
        component: UC818S02,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/notifications/UC818S03',
    children: [
      {
        path: '/inquiry/notifications/UC818S03',
        name: 'UC818S03',
        meta: { title: '連絡事項送信完了' },
        component: UC818S03,
      }
    ]
  },{
    path: '/inquiry',
    component: MainBoard,
    redirect: '/inquiry/notifications/UC818S04',
    children: [
      {
        path: '/inquiry/notifications/UC818S04',
        name: 'UC818S04',
        meta: { title: '連絡事項送信完了' },
        component: UC818S04,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S01',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S01',
        name: 'UC819S01',
        meta: { title: 'ユーザーID登録準備' },
        component: UC819S01,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S02',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S02',
        name: 'UC819S02',
        meta: { title: 'ユーザーID 登録申請' },
        component: UC819S02,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S03',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S03',
        name: 'UC819S03',
        meta: { title: 'ユーザー登録' },
        component: UC819S03,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S04',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S04',
        name: 'UC819S04',
        meta: { title: 'ユーザー登録内容確認' },
        component: UC819S04,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S05',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S05',
        name: 'UC819S05',
        meta: { title: '登録ユーザー一覧' },
        component: UC819S05,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S06',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S06',
        name: 'UC819S06',
        meta: { title: 'ユーザー情報変更' },
        component: UC819S06,
      }
    ]
  },
  {
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S07',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S07',
        name: 'UC819S07',
        meta: { title: '担当部署設定' },
        component: UC819S07,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S08',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S08',
        name: 'UC819S08',
        meta: { title: '承認者設定' },
        component: UC819S08,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S09',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S09',
        name: 'UC819S09',
        meta: { title: '機能設定' },
        component: UC819S09,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S10',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S10',
        name: 'UC819S10',
        meta: { title: 'ユーザー登録情報変更完了' },
        component: UC819S10,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S11',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S11',
        name: 'UC819S11',
        meta: { title: '顧客・顧客利用部署登録' },
        component: UC819S11,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S12',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S12',
        name: 'UC819S12',
        meta: { title: 'ユーザー登録確認' },
        component: UC819S12,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S13',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S13',
        name: 'UC819S13',
        meta: { title: 'ユーザー登録完了' },
        component: UC819S13,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S14',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S14',
        name: 'UC819S14',
        meta: { title: 'ユーザー登録情報変更 内容確認' },
        component: UC819S14,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S15',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S15',
        name: 'UC819S15',
        meta: { title: 'ユーザー登録情報変更完了' },
        component: UC819S15,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S16',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S16',
        name: 'UC819S16',
        meta: { title: '顧客利用部署登録' },
        component: UC819S16,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S17',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S17',
        name: 'UC819S17',
        meta: { title: '代表ユーザーID登録申請内容確認' },
        component: UC819S17,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S18',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S18',
        name: 'UC819S18',
        meta: { title: '代表ユーザーID登録申請内容確認' },
        component: UC819S18,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S19',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S19',
        name: 'UC819S19',
        meta: { title: '代表ユーザーID登録準備内容確認' },
        component: UC819S19,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S20',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S20',
        name: 'UC819S20',
        meta: { title: '代表ユーザーID登録情報変更' },
        component: UC819S20,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S21',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S21',
        name: 'UC819S21',
        meta: { title: '代表ユーザーID登録情報変更内容確認' },
        component: UC819S21,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S22',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S22',
        name: 'UC819S22',
        meta: { title: '代表ユーザーID登録情報変更承認' },
        component: UC819S22,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S23',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S23',
        name: 'UC819S23',
        meta: { title: '代表ユーザーID利用停止申請' },
        component: UC819S23,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S24',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S24',
        name: 'UC819S24',
        meta: { title: '代表ユーザーID利用停止申請確認' },
        component: UC819S24,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S25',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S25',
        name: 'UC819S25',
        meta: { title: '代表ユーザーID利用停止申請承認' },
        component: UC819S25,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S26',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S26',
        name: 'UC819S26',
        meta: { title: 'ユーザー登録情報変更' },
        component: UC819S26,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S27',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S27',
        name: 'UC819S27',
        meta: { title: 'ユーザー登録' },
        component: UC819S27,
      }
    ]
  },
  {
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S28',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S28',
        name: 'UC819S28',
        meta: { title: 'ユーザー登録確認' },
        component: UC819S28,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S29',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S29',
        name: 'UC819S29',
        meta: { title: 'ユーザー情報変更' },
        component: UC819S29,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S30',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S30',
        name: 'UC819S30',
        meta: { title: 'ユーザー情報変更確認' },
        component: UC819S30,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S31',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S31',
        name: 'UC819S31',
        meta: { title: '顧客利用部署変更' },
        component: UC819S31,
      }
    ]
  },{
    path: '/admin-menu',
    component: MainBoard,
    redirect: '/admin-menu/user-regist/UC819S32',
    children: [
      {
        path: '/admin-menu/user-regist/UC819S32',
        name: 'UC819S32',
        meta: { title: '顧客利用部署変更承認' },
        component: UC819S32,
      }
    ]
  },
  {
    path: '/valious-info-inquiry',
    component: MainBoard,
    redirect: '/valious-info-inquiry/UC520S02',
    children: [
      {
        path: '/valious-info-inquiry/UC520S02',
        name: 'UC520S02',
        meta: { title: '在庫照会/明細管理' },
        component: UC520S02,
      }
    ]
  },
  {
    path: '/user-management',
    component: MainBoard,
    redirect: '/user-management/UC513S01',
    children: [
      {
        path: '/user-management/UC513S01',
        name: 'UC513S01',
        meta: { title: 'ユーザー一覧' },
        component: UC513S01,
      },
      {
        path: '/user-management/UC505S01',
        name: 'UC505S01',
        meta: { title: '代表ユーザー情報変更' },
        component: UC505S01,
      },
      {
        path: '/user-management/UC505S02',
        name: 'UC505S02',
        meta: { title: '代表ユーザー変更申請' },
        component: UC505S02,
      },
      {
        path: '/user-management/UC508S01',
        name: 'UC508S01',
        meta: { title: 'システム利用停止申請' },
        component: UC508S01,
      },
      {
        path: '/user-management/UC511S01',
        name: 'UC511S01',
        meta: { title: 'ユーザー詳細' },
        component: UC511S01,
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: 'ログイン' },
    component: Login,
    // redirect: '/login/UC514S02',
  },
  {
    path: '/password-reset/UC516S02',
    name: 'UC516S02',
    meta: { title: 'パスワード設定' },
    component: UC516S02,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

export default router;
