import { createStore } from 'vuex';

const options = {
  state() {
    return {
      loading: false,
      isAlert: false,
      authority: 'admin',
      userId: '',
      headerInfo: {
        userId: '',
        functionId: '',
      },
      userSearchInfo: {
        userName: '',
        funcitonIds: [],
      },
      loginUserData: {
        userId: '',
        userNm: '',
        departmentNm: '',
        telNum: '',
        userKbnCd: '',
        companyCd: '',
        companyNm: '',
        preOfficeCd: '',
        preDepositorCd: '',
        preOfficeNmKj: '',
        preDepositorNm: '',
        mailAdd: '',
        useStopDt: '',
        functionTable: '',
        functionId: [],
        mailAddYsd: [],
        mailAddYsdConfirm: [],
        userInfoSearchOfficeDepositorMasterDtos: []
      },
      tableData: [],
      newInquiryTableData: [
        { mgmtNum: '', docmentNm: '', beginDt: '', endDt: '', expireDt: '', term: '' }
      ],
      newTableEditData: [],
      tableChangeData: [],
      approveTableData: [],
      newTableData: [
        {
          mgmtNum: '',
          docmentNm: '',
          beginDt: '',
          endDt: '',
          expireDt: '',
          term: ''
        }
      ],
      inputMgmtNum: '',
      formData: {
        inputMgmtNum: '',
        mgmtNum: '',
        docmentNm: '',
        beginDt: '',
        endDt: '',
        expireDt: '',
        term: '',
        insideMgmtNum: ''
      },
      LendIssueData: {
        deliveryDate: '',
        searchObj: '',
        remarks: ''
      },
      mgmtNums: [],
      mgmtNum: '',
      orderQuantity: '',
      documentBoxSize: '',
      contactDetails: '',
      docmentNm: '',  
      detailAddNm: [] as string[], // 配列に修正
      newRows: [],
      message: '', // 空文字列で初期化
      oldDetailNms: [],
      newDetailNms: [],
      oldDetailNm: [] as string[],
      newDetailNm: [] as string[],
      tableEditData: {
        basicTable: []
      },
      isNew: '',
      delFlg: '',
      detailEditList: [],
      orderHistoryInquiry: '',
      historyInquiry: '',
      detailNoCounter: 1,
      detailNo: [],
      beginDt: '',
      endDt: '',
      expireDt: '',
      labelPrint: '',
      inquiryNo: '',
      situation: '',
      desireDt: '',
      inputUserId: '',
      inputPassword: '',
      inputMngNm: '',
      inputMngNmKana: '',
      inputEmail: '',
      password: '',
      userNm: '',
      departmentNm: '',
      departmentMngNm: '',
      seniorApproveAssign: '',
      userType: '',
      address1: '',
      address2: '',
      address3: '',
      deliveryTel: '',
      deliveryFaxTel: '',
      deliveryAddNm: '',
      deliveryMngNm: '',
      deliveryMngNmKana: '',
      email: '',
      validStop: '',
      selectedUsers: [],


    };
  },
  mutations: {
    updateNewTableData(state, payload) {
      const index = state.newTableData.findIndex(item => item.mgmtNum === payload.mgmtNum);
      if (index !== -1) {
        // 既存の行を更新
        state.newTableData[index] = { ...state.newTableData[index], ...payload };
      } else {
        // 新しい行を追加
        state.newTableData.push(payload);
      }
    },
    clearTableData(state) {
      state.newInquiryTableData = [
        { mgmtNum: '', docmentNm: '', beginDt: '', endDt: '', expireDt: '', term: '' }
    
      ]; // テーブルデータを空の配列に設定
    },
    clearTableEditData(state) {
      state.newTableEditData = []; // テーブルデータを空の配列に設定
    },
    clearMgmtNums(state) {
      state.mgmtNums = [];
    },
    removeNewItems(state) {
      state.newTableEditData = state.newTableEditData.filter(item => !item.isNew); // isNewがtrueのものを除外
    },
    SET_NEW_TABLE_DATA(state, data) {
      state.newTableData = data;
    },
    UPDATE_NEW_TABLE_ROW(state, { index, row }) {
      state.newTableData[index] = row;
    },
    UPDATE_TABLE_DATA(state, data) {
      state.newInquiryTableData = data;
    },
    updateTableEditData(state, data) {
      state.newTableEditData = data;
    },
    updateTableChangeData(state, data) {
      state.tableChangeData = data;
    },
    updateApproveTableData(state, data) {
      state.approveTableData = data;
    },
    setMgmtNum(state, mgmtNum) {
      state.mgmtNum = mgmtNum;
    },
    setUserId(state, userId) {
      state.userId = userId;
    },
    setUserNm(state, userNm) {
      state.userNm = userNm;
    },
    setPassword(state, password) {
      state.password = password;
    },
    setDeliveryMngNm(state, deliveryMngNm) {
      state.deliveryMngNm = deliveryMngNm;
    },
    setDeliveryMngNmKana(state, deliveryMngNmKana) {
      state.deliveryMngNmKana = deliveryMngNmKana;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setDepartmentNm(state, departmentNm) {
      state.departmentNm = departmentNm;
    },
    setDepartmentMngNm(state, departmentMngNm) {
      state.departmentMngNm = departmentMngNm;
    },
    setAddress1(state, address1) {
      state.address1 = address1;
    },
    setAddress2(state, address2) {
      state.address2 = address2;
    },
    setAddress3(state, address3) {
      state.address3 = address3;
    },
    setDeliveryTel(state, deliveryTel) {
      state.deliveryTel = deliveryTel;
    },
    setDeliveryFaxTel(state, deliveryFaxTel) {
      state.deliveryFaxTel = deliveryFaxTel;
    },
    setDeliveryAddNm(state, deliveryAddNm) {
      state.deliveryAddNm = deliveryAddNm;
    },
    setInputUserId(state, inputUserId) {
      state.inputUserId = inputUserId;
    },
    setInputPassword(state, inputPassword) {
      state.inputPassword = inputPassword;
    },
    setInputMngNm(state, inputMngNm) {
      state.inputMngNm = inputMngNm;
    },
    setInputMngNmKana(state, inputMngNmKana) {
      state.inputMngNmKana = inputMngNmKana;
    },
    setInputEmail(state, inputEmail) {
      state.inputEmail = inputEmail;
    },
    setInquiryNo(state, inquiryNo) {
      state.inquiryNo = inquiryNo;
    },
    setSituation(state, situation) {
      state.situation = situation;
    },
    setDesireDt(state, desireDt) {
      state.desireDt = desireDt;
    },
    setContactDetails(state, contactDetails) {
      state.contactDetails = contactDetails;
    },
    setOrderQuantity(state, orderQuantity) {
      state.orderQuantity = orderQuantity;
    },
    setDocumentBoxSize(state, documentBoxSize) {
      state.documentBoxSize = documentBoxSize;
    },
    setDocmentNm(state, docmentNm) {
      state.docmentNm = docmentNm;
    },
    setTerm(state, term) {
      state.term = term;
    },
    setDetailNm(state, newDetailNm) {
      state.newDetailNm = newDetailNm;
    },
    setDetailAddNm(state, { index, newDetailNm }) {
      // 配列として保存
      state.newTableEditData[index].newDetailNm = newDetailNm

      // 明細Noを生成し、stateに保存
      state.detailNo = [];
      for (let i = 0; i < newDetailNm.length; i++) {
        state.detailNo.push(state.detailNoCounter++);
      }
    },
    addDetailAddNm(state, detailAddNm) {
      // 配列に追加
      state.detailAddNm.push(detailAddNm);

      // 新しい明細Noを生成し、stateに追加
      state.detailNo.push(state.detailNoCounter++);
    },
    setNewDetailNm(state, { index, newDetailNm }) {
      // state.newDetailNm = newDetailNm;
      state.detailEditList[index].newDetailNm = newDetailNm
    },
    setOldDetailNm(state, oldDetailNm) {
      state.oldDetailNm = oldDetailNm;
    },
    setDelFlg(state, { index, delFlg }) {
      state.detailEditList[index].delFlg = delFlg;
    },
    setIsNew(state, { index, isNew }) {
      state.newTableEditData[index].isNew = isNew;
    },
    setMgmtNums(state, mgmtNums) {
      state.mgmtNums = mgmtNums;
    },
    setSelectedUsers(state, users) {
      state.selectedUsers = users;
    },
    setDocumentChange(state, documentChange) {
      state.documentChange = documentChange;
    },
    setHistoryInquiry(state, historyInquiry) {
      state.historyInquiry = historyInquiry;
    },
    setOrderHistoryInquiry(state, orderHistoryInquiry) {
      state.orderHistoryInquiry = orderHistoryInquiry;
    },
   
  
    addMgmtNum(state, mgmtNum) {
      state.mgmtNums.push(mgmtNum);
    },
    
    addOldDetailNm(state, oldDetailNm) {
      state.oldDetailNm.push(oldDetailNm);
    },
    addNewDetailNm(state, newDetailNm) {
      state.newDetailNm.push(newDetailNm);
    },
    addNewDetailAddNm(state, detailAddNm) {
      state.detailAddNm += detailAddNm; // 追加で文字列として保存
    },
    addDocumentChange(state, documentChange) {
      state.documentChange.push(documentChange);
    },
    addHistoryInquiry(state, historyInquiry) {
      state.historyInquiry.push(historyInquiry);
    },
    addOrderHistoryInquiry(state, orderHistoryInquiry) {
      state.orderHistoryInquiry.push(orderHistoryInquiry);
    },
    setDeliveryDate(state, date) {
      state.LendIssueData.deliveryDate = date;
    },
    setBeginDt(state, date) {
      state.beginDt = date;
    },
    setEndDt(state, date) {
      state.endDt = date;
    },
    setExpireDt(state, date) {
      state.expireDt = date;
    },
    setRemarks(state, remarks) {
      state.LendIssueData.remarks = remarks;
    },
    setSearchObj(state, searchObj) {
      state.LendIssueData.searchObj = searchObj;
    },
    setLabelPrint(state, labelPrint) {
      state.labelPrint = labelPrint;
    },
    setSeniorApproveAssign(state, seniorApproveAssign) {
      state.seniorApproveAssign = seniorApproveAssign;
    },
    setUserType(state, userType) {
      state.userType = userType;
    },
    setValidStop(state, validStop) {
      state.validStop = validStop;
    },
    setDetailEditList(state, detailEditList) {
      state.detailEditList = detailEditList;
    },
    addDetailEditRow(state, newRow) {
      state.detailEditList.push(newRow);
    },
    updateTableData(state, payload) {
      state.formData = payload;
    },
    updateInputMgmtNum(state, payload) {
      state.inputMgmtNum = payload;
    },
    updateFormData(state, payload) {
      state.formData = { ...state.formData, ...payload };
    },
    setMessage(state, message) {
      // messageの値を true, false, 空文字列 のいずれかに設定
      if (message === true || message === false || message === '') {
        state.message = message;
      } else {
        console.error('Invalid value for message:', message);
      }
    },
  },
  actions: {
    updateNewInquiryTableData({ commit }, newInquiryTableData) {
      commit('UPDATE_TABLE_DATA', newInquiryTableData);
    },
    updateNewTableEditData({ commit }, newTableEditData) {
      commit('updateNewTableEditData', newTableEditData);
    },
    updateTableChangeData({ commit }, tableChangeData) {
      commit('updateTableChangeData', tableChangeData);
    },
    updateApproveTableData({ commit }, approveTableData) {
      commit('updateApproveTableData', approveTableData);
    },
    setNewTableData({ commit }, data) {
      commit('SET_NEW_TABLE_DATA', data);
    },
    updateNewTableRow({ commit }, payload) {
      commit('UPDATE_NEW_TABLE_ROW', payload);
    },
    updateDeliveryDate({ commit }, date) {
      commit('setDeliveryDate', date);
    },
    updateBeginDt({ commit }, date) {
      commit('setBeginDt', date);
    },
    updateEndDt({ commit }, date) {
      commit('setEndDt', date);
    },
    updateExpireDt({ commit }, date) {
      commit('setExpireDt', date);
    },
    updateRemarks({ commit }, remarks) {
      commit('setRemarks', remarks);
    },
    updateSearchObj({ commit }, searchObj) {
      commit('setSearchObj', searchObj);
    },
    updateLabelPrint({ commit }, labelPrint) {
      commit('setLabelPrint', labelPrint);
    },
    updateSeniorApproveAssign({ commit }, seniorApproveAssign) {
      commit('setSeniorApproveAssign', seniorApproveAssign);
    },
    updateUserType({ commit }, userType) {
      commit('setUserType', userType);
    },
    updateValidStop({ commit }, validStop) {
      commit('setValidStop', validStop);
    },
    updateTableData({ commit }, payload) {
      commit('updateTableData', payload);
    },
    updateInputMgmtNum({ commit }, payload) {
      commit('updateInputMgmtNum', payload);
    },
    saveFormDataAction({ commit }, formData) {
      commit('updateFormData', formData);
    },
    updateOldDetailNm({ commit }, oldDetailNm) {
      commit('setOldDetailNm', oldDetailNm);
    },
    updateNewDetailNm({ commit }, newDetailNm) {
      commit('setNewDetailNm', newDetailNm);
    }, 
    updateDepartmentNm({ commit }, departmentNm) {
      commit('setDepartmentNm', departmentNm);
    },
    updateUserId({ commit }, userId) {
      commit('setUserId', userId);
    },
    updatePassword({ commit }, password) {
      commit('setPassword', password);
    },
    updateDeliveryMngNm({ commit }, deliveryMngNm) {
      commit('setDeliveryMngNm', deliveryMngNm);
    },
    updateDeliveryMngNmKana({ commit }, deliveryMngNmKana) {
      commit('setDeliveryMngNmKana', deliveryMngNmKana);
    },
    updateEmail({ commit }, email) {
      commit('setEmail', email);
    },
    updateAddress1({ commit }, address1) {
      commit('setAddress1', address1);
    },
    updateAddress2({ commit }, address2) {
      commit('setAddress2', address2);
    },
    updateAddress3({ commit }, address3) {
      commit('setAddress3', address3);
    },
    updateDeliveryTel({ commit }, deliveryTel) {
      commit('setDeliveryTel', deliveryTel);
    },
    updateDeliveryFaxTel({ commit }, deliveryFaxTel) {
      commit('setDeliveryFaxTel', deliveryFaxTel);
    },
    updateDeliveryAddNm({ commit }, deliveryAddNm) {
      commit('setDeliveryAddNm', deliveryAddNm);
    },
    async updateDetailAddNm({ commit }, detailAddNm) {
      console.log('Before setting detailAddNm:', detailAddNm);
      commit('setDetailAddNm', detailAddNm);
    },
    updateMessage({ commit }, message) {
      commit('setMessage', message);
    },
 
  },
  getters: {
    getDeliveryDate: (state) => state.LendIssueData.deliveryDate,
    getBeginDt: (state) => state.beginDt,
    getEndDt: (state) => state.endDt,
    getExpireDt: (state) => state.expireDt,
    getRemarks: (state) => state.LendIssueData.remarks,
    getSearchObj: (state) => state.LendIssueData.searchObj,
    getLabelPrint: (state) => state.labelPrint,
    getSeniorApproveAssign: (state) => state.seniorApproveAssign,
    getUserType: (state) => state.userType,
    getValidStop: (state) => state.validStop,
    getTableData: (state) => state.formData,
    getInputMgmtNum: state => state.inputMgmtNum,
    oldDetailNm: state => state.oldDetailNm,
    newDetailNm: state => state.newDetailNm,
    detailAddNm: state => state.detailAddNm,
    detailNo: state => state.detailNo.map(no => no.toString()),
    message: state => state.message,
    newInquiryTableData: state => state.newInquiryTableData,
    newTableEditData: state => state.newTableEditData,
    tableChangeData: state => state.tableChangeData,
    approveTableData: state => state.approveTableData
  },
  modules: {},
};

export const store = createStore({
  ...options
});

export function resetStore() {
  store.replaceState(createStore(options).state);
}

export default store;
